import {
  EAppType,
  EProductType,
  IPayload,
  IProduct,
} from '@quesmed/types-rn/models';

// eslint-disable-next-line import/no-cycle
import { Nullable } from './general';

export type AppProductType =
  | EProductType.ALL
  | EProductType.QBANK
  | EProductType.OSCE
  | EProductType.BUNDLE
  | EProductType.MEDICAL_SCIENCES
  | EProductType.ANATOMY
  | EProductType.ANATOMY_BUNDLE
  | EProductType.MRCP_PART1
  | EProductType.MRCP_PART2
  | EProductType.PACES
  | EProductType.INTERVIEW_ANAESTHETICS
  | EProductType.INTERVIEW_CST
  | EProductType.INTERVIEW_IMT
  | EProductType.INTERVIEW_RADIOLOGY
  | EProductType.MSRA
  | EProductType.PLAB1
  | EProductType.PLAB2;

export enum QuesmedAppName {
  AMS = 'Anatomy & Pre-Clinical',
  CM_UKMLA = 'Clinical & MLA',
  MRCP = 'MRCP Examinations',
  INTERVIEW = 'Interview Qbanks',
  MSRA = 'MSRA Examinations',
  MSRA_FULL_NAME = 'Multi-Specialty Recruitment Assessment (MSRA) Examinations',
  PLAB = 'PLAB',
}

export const QuesmedAppNames = {
  [EAppType.AMS]: QuesmedAppName.AMS,
  [EAppType.CM_UKMLA]: QuesmedAppName.CM_UKMLA,
  [EAppType.MRCP]: QuesmedAppName.MRCP,
  [EAppType.INTERVIEW]: QuesmedAppName.INTERVIEW,
  [EAppType.MSRA]: QuesmedAppName.MSRA_FULL_NAME,
  [EAppType.PLAB]: QuesmedAppName.PLAB,
};

export enum QuesmedProductName {
  ALL = 'All products',
  QBANK = 'Qbank (AKT)',
  OSCE = 'OSCE (CPSA)',
  BUNDLE = 'Bundle',
  ANATOMY = 'Anatomy',
  MEDICAL_SCIENCES = 'Pre-Clinical',
  ANATOMY_BUNDLE = 'Anatomy & Pre-Clinical Bundle',
  MRCP_PART1 = 'MRCP Part 1',
  MRCP_PART2 = 'MRCP Part 2',
  PACES = 'MRCP PACES',
  MSRA = 'MSRA',
  MSRA_FULL_NAME = 'Multi-Specialty Recruitment Assessment (MSRA) Examinations',
  INTERVIEW_ANAESTHETICS = 'Anaesthetics Interview',
  INTERVIEW_CST = 'CST Interview',
  INTERVIEW_IMT = 'IMT Interview',
  INTERVIEW_RADIOLOGY = 'Radiology Interview',
  PLAB1 = 'PLAB 1',
  PLAB2 = 'PLAB 2',
}

export const quesmedProductNames = {
  [EProductType.ALL]: QuesmedProductName.ALL,
  [EProductType.QBANK]: QuesmedProductName.QBANK,
  [EProductType.OSCE]: QuesmedProductName.OSCE,
  [EProductType.BUNDLE]: QuesmedProductName.ANATOMY_BUNDLE,
  [EProductType.MEDICAL_SCIENCES]: QuesmedProductName.MEDICAL_SCIENCES,
  [EProductType.ANATOMY]: QuesmedProductName.ANATOMY,
  [EProductType.ANATOMY_BUNDLE]: QuesmedProductName.ANATOMY_BUNDLE,
  [EProductType.MRCP_PART1]: QuesmedProductName.MRCP_PART1,
  [EProductType.MRCP_PART2]: QuesmedProductName.MRCP_PART2,
  [EProductType.PACES]: QuesmedProductName.PACES,
  [EProductType.INTERVIEW_ANAESTHETICS]:
    QuesmedProductName.INTERVIEW_ANAESTHETICS,
  [EProductType.INTERVIEW_CST]: QuesmedProductName.INTERVIEW_CST,
  [EProductType.INTERVIEW_IMT]: QuesmedProductName.INTERVIEW_IMT,
  [EProductType.INTERVIEW_RADIOLOGY]: QuesmedProductName.INTERVIEW_RADIOLOGY,
  [EProductType.MSRA]: QuesmedProductName.MSRA,
  [EProductType.PLAB1]: QuesmedProductName.PLAB1,
  [EProductType.PLAB2]: QuesmedProductName.PLAB2,
};

export interface QuesmedProductData {
  name: QuesmedProductName;
  value: AppProductType;
  active: boolean;
}

export interface QuesmedAppData {
  active: boolean;
  name: QuesmedAppName;
  value: EAppType;
  products: {
    [key in AppProductType]?: QuesmedProductData;
  };
}

export interface UserSubscription {
  subscription: AppProductType;
  renewalDate: Nullable<number | Date>;
  app: EAppType;
  price?: number;
  duration?: number;
  subscriptionId: number;
  isCanceled: boolean;
  canBeUpdated: boolean;
}

export interface SortedSubscriptions {
  [EAppType.AMS]: UserSubscription[];
  [EAppType.CM_UKMLA]: UserSubscription[];
  [EAppType.MRCP]: UserSubscription[];
  [EAppType.INTERVIEW]: UserSubscription[];
  [EAppType.MSRA]: UserSubscription[];
  [EAppType.PLAB]: UserSubscription[];
}

export const productsApp: { [key in AppProductType]: EAppType } = {
  [EProductType.ALL]: EAppType.CM_UKMLA,
  [EProductType.QBANK]: EAppType.CM_UKMLA,
  [EProductType.OSCE]: EAppType.CM_UKMLA,
  [EProductType.BUNDLE]: EAppType.CM_UKMLA,
  [EProductType.MEDICAL_SCIENCES]: EAppType.AMS,
  [EProductType.ANATOMY]: EAppType.AMS,
  [EProductType.ANATOMY_BUNDLE]: EAppType.AMS,
  [EProductType.MRCP_PART1]: EAppType.MRCP,
  [EProductType.MRCP_PART2]: EAppType.MRCP,
  [EProductType.PACES]: EAppType.MRCP,
  [EProductType.INTERVIEW_ANAESTHETICS]: EAppType.INTERVIEW,
  [EProductType.INTERVIEW_CST]: EAppType.INTERVIEW,
  [EProductType.INTERVIEW_IMT]: EAppType.INTERVIEW,
  [EProductType.INTERVIEW_RADIOLOGY]: EAppType.INTERVIEW,
  [EProductType.MSRA]: EAppType.MSRA,
  [EProductType.PLAB1]: EAppType.PLAB,
  [EProductType.PLAB2]: EAppType.PLAB,
};

export type QuesmedApps = {
  [key in EAppType]: QuesmedAppData;
};

export const SubscriptionNames = {
  [EProductType.ALL]: QuesmedProductName.QBANK,
  [EProductType.QBANK]: QuesmedProductName.QBANK,
  [EProductType.OSCE]: QuesmedProductName.OSCE,
  [EProductType.BUNDLE]: QuesmedProductName.BUNDLE,
  [EProductType.ANATOMY]: QuesmedProductName.ANATOMY,
  [EProductType.MEDICAL_SCIENCES]: QuesmedProductName.MEDICAL_SCIENCES,
  [EProductType.ANATOMY_BUNDLE]: QuesmedProductName.ANATOMY_BUNDLE,
  [EProductType.MSRA]: QuesmedProductName.MSRA_FULL_NAME,
  [EProductType.INTERVIEW_ANAESTHETICS]:
    QuesmedProductName.INTERVIEW_ANAESTHETICS,
  [EProductType.INTERVIEW_CST]: QuesmedProductName.INTERVIEW_CST,
  [EProductType.INTERVIEW_IMT]: QuesmedProductName.INTERVIEW_IMT,
  [EProductType.INTERVIEW_RADIOLOGY]: QuesmedProductName.INTERVIEW_RADIOLOGY,
  [EProductType.MRCP_PART1]: QuesmedProductName.MRCP_PART1,
  [EProductType.MRCP_PART2]: QuesmedProductName.MRCP_PART2,
  [EProductType.PACES]: QuesmedProductName.PACES,
  [EProductType.PLAB1]: QuesmedProductName.PLAB1,
  [EProductType.PLAB2]: QuesmedProductName.PLAB2,
};

export interface SubscriptionData {
  name: QuesmedProductName;
  value: AppProductType;
  app: EAppType;
}

export type Subscriptions = {
  [key in AppProductType]: SubscriptionData;
};

export enum PathName {
  Anatomy = 'Anatomy',
  Clinical = 'Clinical',
  CommunicationSkills = 'Communication Skills',
  ContentMap = 'MLA Content Map',
  CoreClinical = 'Core Clinical',
  Dashboard = 'Dashboard',
  DataInterpretation = 'Data Interpretation',
  Examination = 'Examination',
  FinalsMock = 'Finals Mock',
  Flashcards = 'Flashcards',
  HistoryTaking = 'History Taking',
  KnowledgeLibrary = 'Knowledge library',
  LearningMaterials = 'Learning Materials',
  MockTests = 'Mock tests',
  Practice = 'Practice',
  PreClinical = 'Pre-Clinical',
  PSAMock = 'PSA Mock',
  Questions = 'Questions',
  Stations = 'Stations',
  VideoLibrary = 'Video Library',
  PastPapers = 'Past Papers',
  MyNotes = 'My Notes',
}

export interface ExtendedProduct extends IProduct {
  formattedDuration: string;
  formattedPrice: string;
  bundle: boolean;
  features: string | null;
  app: EAppType;
}

export type GroupedProducts = {
  [key: number]: ExtendedProduct[];
};

export const SUBSCRIPTIONS: Subscriptions = {
  [EProductType.OSCE]: {
    app: EAppType.CM_UKMLA,
    name: QuesmedProductName.OSCE,
    value: EProductType.OSCE,
  },
  [EProductType.QBANK]: {
    app: EAppType.CM_UKMLA,
    name: QuesmedProductName.QBANK,
    value: EProductType.QBANK,
  },
  [EProductType.BUNDLE]: {
    app: EAppType.CM_UKMLA,
    name: QuesmedProductName.BUNDLE,
    value: EProductType.BUNDLE,
  },
  [EProductType.ANATOMY]: {
    app: EAppType.AMS,
    name: QuesmedProductName.ANATOMY,
    value: EProductType.ANATOMY,
  },
  [EProductType.MEDICAL_SCIENCES]: {
    app: EAppType.AMS,
    name: QuesmedProductName.MEDICAL_SCIENCES,
    value: EProductType.MEDICAL_SCIENCES,
  },
  [EProductType.ANATOMY_BUNDLE]: {
    app: EAppType.AMS,
    name: QuesmedProductName.ANATOMY_BUNDLE,
    value: EProductType.ANATOMY_BUNDLE,
  },
  [EProductType.MSRA]: {
    app: EAppType.MSRA,
    name: QuesmedProductName.MSRA,
    value: EProductType.MSRA,
  },
  [EProductType.INTERVIEW_ANAESTHETICS]: {
    app: EAppType.INTERVIEW,
    name: QuesmedProductName.INTERVIEW_ANAESTHETICS,
    value: EProductType.INTERVIEW_ANAESTHETICS,
  },
  [EProductType.INTERVIEW_CST]: {
    app: EAppType.INTERVIEW,
    name: QuesmedProductName.INTERVIEW_CST,
    value: EProductType.INTERVIEW_CST,
  },
  [EProductType.INTERVIEW_IMT]: {
    app: EAppType.INTERVIEW,
    name: QuesmedProductName.INTERVIEW_IMT,
    value: EProductType.INTERVIEW_IMT,
  },
  [EProductType.INTERVIEW_RADIOLOGY]: {
    app: EAppType.INTERVIEW,
    name: QuesmedProductName.INTERVIEW_RADIOLOGY,
    value: EProductType.INTERVIEW_RADIOLOGY,
  },
  [EProductType.MRCP_PART1]: {
    app: EAppType.MRCP,
    name: QuesmedProductName.MRCP_PART1,
    value: EProductType.MRCP_PART1,
  },
  [EProductType.MRCP_PART2]: {
    app: EAppType.MRCP,
    name: QuesmedProductName.MRCP_PART2,
    value: EProductType.MRCP_PART2,
  },
  [EProductType.PACES]: {
    app: EAppType.MRCP,
    name: QuesmedProductName.PACES,
    value: EProductType.PACES,
  },
  [EProductType.PLAB1]: {
    app: EAppType.PLAB,
    name: QuesmedProductName.PLAB1,
    value: EProductType.PLAB1,
  },
  [EProductType.PLAB2]: {
    app: EAppType.PLAB,
    name: QuesmedProductName.PLAB2,
    value: EProductType.PLAB2,
  },
  [EProductType.ALL]: {
    app: EAppType.CM_UKMLA,
    name: QuesmedProductName.QBANK,
    value: EProductType.QBANK,
  },
};

export const APPS: QuesmedApps = {
  [EAppType.CM_UKMLA]: {
    active: true,
    name: QuesmedAppName.CM_UKMLA,
    products: {
      [EProductType.QBANK]: {
        active: true,
        value: EProductType.QBANK,
        name: QuesmedProductName.QBANK,
      },
      [EProductType.OSCE]: {
        active: true,
        value: EProductType.OSCE,
        name: QuesmedProductName.OSCE,
      },
    },
    value: EAppType.CM_UKMLA,
  },
  [EAppType.AMS]: {
    active: true,
    name: QuesmedAppName.AMS,
    products: {
      [EProductType.MEDICAL_SCIENCES]: {
        active: true,
        value: EProductType.MEDICAL_SCIENCES,
        name: QuesmedProductName.MEDICAL_SCIENCES,
      },
      [EProductType.ANATOMY]: {
        active: true,
        value: EProductType.ANATOMY,
        name: QuesmedProductName.ANATOMY,
      },
    },
    value: EAppType.AMS,
  },
  [EAppType.MRCP]: {
    active: true,
    name: QuesmedAppName.MRCP,
    products: {
      [EProductType.MRCP_PART1]: {
        active: true,
        value: EProductType.MRCP_PART1,
        name: QuesmedProductName.MRCP_PART1,
      },
      [EProductType.MRCP_PART2]: {
        active: true,
        value: EProductType.MRCP_PART2,
        name: QuesmedProductName.MRCP_PART2,
      },
      [EProductType.PACES]: {
        active: true,
        value: EProductType.PACES,
        name: QuesmedProductName.PACES,
      },
    },
    value: EAppType.MRCP,
  },
  [EAppType.INTERVIEW]: {
    active: true,
    name: QuesmedAppName.INTERVIEW,
    products: {
      [EProductType.INTERVIEW_ANAESTHETICS]: {
        active: true,
        value: EProductType.INTERVIEW_ANAESTHETICS,
        name: QuesmedProductName.INTERVIEW_ANAESTHETICS,
      },
      [EProductType.INTERVIEW_IMT]: {
        active: true,
        value: EProductType.INTERVIEW_IMT,
        name: QuesmedProductName.INTERVIEW_IMT,
      },
      [EProductType.INTERVIEW_CST]: {
        active: true,
        value: EProductType.INTERVIEW_CST,
        name: QuesmedProductName.INTERVIEW_CST,
      },
      [EProductType.INTERVIEW_RADIOLOGY]: {
        active: true,
        value: EProductType.INTERVIEW_RADIOLOGY,
        name: QuesmedProductName.INTERVIEW_RADIOLOGY,
      },
    },
    value: EAppType.INTERVIEW,
  },
  [EAppType.MSRA]: {
    active: true,
    name: QuesmedAppName.MSRA,
    products: {
      [EProductType.MSRA]: {
        active: true,
        value: EProductType.MSRA,
        name: QuesmedProductName.MSRA,
      },
    },
    value: EAppType.MSRA,
  },
  [EAppType.PLAB]: {
    active: true,
    name: QuesmedAppName.PLAB,
    products: {
      [EProductType.PLAB1]: {
        active: true,
        value: EProductType.PLAB1,
        name: QuesmedProductName.PLAB1,
      },
      [EProductType.PLAB2]: {
        active: true,
        value: EProductType.PLAB2,
        name: QuesmedProductName.PLAB2,
      },
    },
    value: EAppType.PLAB,
  },
};

export enum ProductState {
  Active,
  Inactive,
  AboutToExpired,
  Expired,
}

export type ProductType = keyof Pick<
  IPayload,
  | 'qbankSubscriptionEndDate'
  | 'osceSubscriptionEndDate'
  | 'bundleSubscriptionEndDate'
  | 'anatomySubscriptionEndDate'
  | 'medicalSciencesSubscriptionEndDate'
  | 'anatomyBundleSubscriptionEndDate'
  | 'mrcpPart1SubscriptionEndDate'
  | 'mrcpPart2SubscriptionEndDate'
  | 'pacesSubscriptionEndDate'
  | 'msraSubscriptionEndDate'
  | 'anaestheticsInterviewSubscriptionEndDate'
  | 'cstInterviewSubscriptionEndDate'
  | 'imtInterviewSubscriptionEndDate'
  | 'radiologyInterviewSubscriptionEndDate'
  | 'plab1SubscriptionEndDate'
  | 'plab2SubscriptionEndDate'
>;

// Can't use Nullable type due to dependency cycle issue
export type WithSubscriptionDates = { [key in ProductType]: number | null };

export type SubscriptionsState = { [key in ProductType]: ProductState };

export type ProductSubscriptionsState = {
  [key in AppProductType]: ProductState;
};

export type ProductPerSubscription = { [key in ProductType]: AppProductType };

type AllowedProducts = { [key in ProductType]: AppProductType[] };

export const ALLOWED_PRODUCTS: AllowedProducts = {
  qbankSubscriptionEndDate: [EProductType.QBANK],
  osceSubscriptionEndDate: [EProductType.OSCE],
  bundleSubscriptionEndDate: [EProductType.QBANK, EProductType.OSCE],
  anatomySubscriptionEndDate: [EProductType.ANATOMY],
  medicalSciencesSubscriptionEndDate: [EProductType.MEDICAL_SCIENCES],
  anatomyBundleSubscriptionEndDate: [
    EProductType.ANATOMY,
    EProductType.MEDICAL_SCIENCES,
  ],
  mrcpPart1SubscriptionEndDate: [EProductType.MRCP_PART1],
  mrcpPart2SubscriptionEndDate: [EProductType.MRCP_PART2],
  pacesSubscriptionEndDate: [EProductType.PACES],
  msraSubscriptionEndDate: [EProductType.MSRA],
  anaestheticsInterviewSubscriptionEndDate: [
    EProductType.INTERVIEW_ANAESTHETICS,
  ],
  cstInterviewSubscriptionEndDate: [EProductType.INTERVIEW_CST],
  imtInterviewSubscriptionEndDate: [EProductType.INTERVIEW_IMT],
  radiologyInterviewSubscriptionEndDate: [EProductType.INTERVIEW_RADIOLOGY],
  plab1SubscriptionEndDate: [EProductType.PLAB1],
  plab2SubscriptionEndDate: [EProductType.PLAB2],
};

export const PRODUCT_TYPE_PER_SUBSCRIPTION_KEY: ProductPerSubscription = {
  qbankSubscriptionEndDate: EProductType.QBANK,
  osceSubscriptionEndDate: EProductType.OSCE,
  bundleSubscriptionEndDate: EProductType.BUNDLE,
  anatomySubscriptionEndDate: EProductType.ANATOMY,
  medicalSciencesSubscriptionEndDate: EProductType.MEDICAL_SCIENCES,
  anatomyBundleSubscriptionEndDate: EProductType.ANATOMY_BUNDLE,
  mrcpPart1SubscriptionEndDate: EProductType.MRCP_PART1,
  mrcpPart2SubscriptionEndDate: EProductType.MRCP_PART2,
  pacesSubscriptionEndDate: EProductType.PACES,
  msraSubscriptionEndDate: EProductType.MSRA,
  anaestheticsInterviewSubscriptionEndDate: EProductType.INTERVIEW_ANAESTHETICS,
  cstInterviewSubscriptionEndDate: EProductType.INTERVIEW_CST,
  imtInterviewSubscriptionEndDate: EProductType.INTERVIEW_IMT,
  radiologyInterviewSubscriptionEndDate: EProductType.INTERVIEW_RADIOLOGY,
  plab1SubscriptionEndDate: EProductType.PLAB1,
  plab2SubscriptionEndDate: EProductType.PLAB2,
};

export const APP_SPECIFIC_PATHS = {
  [EAppType.CM_UKMLA]: [
    'practice-materials',
    'video-library',
    'questions',
    'stations',
    'flashcards',
    'mock-tests',
    'mla-content-map',
  ],
  [EAppType.AMS]: ['video-library', 'questions', 'flashcards', 'mock-tests'],
  [EAppType.MSRA]: ['questions'],
  [EAppType.MRCP]: ['video-library', 'questions', 'stations', 'mock-tests'],
  [EAppType.INTERVIEW]: ['video-library'],
  [EAppType.PLAB]: [
    'video-library',
    'questions',
    'stations',
    'mock-tests',
    'mla-content-map',
  ],
};

export const PRODUCT_SPECIFIC_PATHS = {
  [EProductType.ALL]: [],
  [EProductType.QBANK]: [
    'practice-materials',
    'video-library',
    'questions',
    'flashcards',
    'mock-tests',
    'mla-content-map',
  ],
  [EProductType.OSCE]: ['practice-materials', 'video-library', 'stations'],
  [EProductType.BUNDLE]: [],
  [EProductType.ANATOMY]: [
    'video-library',
    'questions',
    'flashcards',
    'mock-tests',
  ],
  [EProductType.MEDICAL_SCIENCES]: ['questions', 'flashcards', 'mock-tests'],
  [EProductType.ANATOMY_BUNDLE]: [],
  [EProductType.MSRA]: ['questions'],
  [EProductType.INTERVIEW_ANAESTHETICS]: ['video-library'],
  [EProductType.INTERVIEW_CST]: ['video-library'],
  [EProductType.INTERVIEW_IMT]: ['video-library'],
  [EProductType.INTERVIEW_RADIOLOGY]: ['video-library'],
  [EProductType.MRCP_PART1]: [
    'questions',
    'mock-tests',
    'knowledge-library',
    'video-library',
  ],
  [EProductType.MRCP_PART2]: [
    'questions',
    'mock-tests',
    'knowledge-library',
    'video-library',
  ],
  [EProductType.PACES]: [
    'practice-materials',
    'knowledge-library',
    'video-library',
    'stations',
  ],
  [EProductType.PLAB1]: [
    'questions',
    'mock-tests',
    'mla-content-map',
    'video-library',
    'practice-materials',
  ],
  [EProductType.PLAB2]: ['practice-materials', 'video-library', 'stations'],
};

export const VIDEO_PRODUCTS = [
  EProductType.QBANK,
  EProductType.OSCE,
  EProductType.PACES,
  EProductType.ANATOMY,
  EProductType.PLAB1,
  EProductType.PLAB2,
  EProductType.INTERVIEW_ANAESTHETICS,
  EProductType.INTERVIEW_IMT,
  EProductType.INTERVIEW_CST,
  EProductType.INTERVIEW_RADIOLOGY,
];
